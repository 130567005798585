<script setup lang="ts">
  import { computed } from 'vue'
  import { useRoute } from 'vue-router'
  import { helpCenterUrl } from '@/models/app'
  import { usePageStore } from '@/stores/page'
  import TheNavbarUserDropdown from '../navbar/the-navbar-user-dropdown.vue'

  const route = useRoute()
  const servicePages = ['service', 'service detail', 'product list', 'product detail']
  const isInServicePages = computed(() => servicePages.includes(route.name as string))

  const page = usePageStore()
</script>

<template>
  <div class="fixed inset-x-0 bottom-0 z-10 container lg:hidden">
    <div class="menu-bar">
      <app-button
        class="menu-bar-item"
        :to="{ name: 'home' }"
      >
        <app-icon
          class="icon-active"
          icon="i-gts-home"
        />
        <app-icon
          class="icon-inactive"
          icon="i-gts-home-gray"
        />
        <div>Home</div>
      </app-button>
      <app-dropdown
        center
        top
      >
        <template #default="{ open }">
          <app-button
            class="menu-bar-item"
            :class="{ 'router-link-active': open || isInServicePages }"
          >
            <app-icon
              class="icon-active"
              icon="i-gts-hammer-wrench"
            />
            <app-icon
              class="icon-inactive"
              icon="i-gts-hammer-wrench-gray"
            />
            <div>Layanan</div>
          </app-button>
        </template>

        <template #items>
          <app-dropdown-item
            icon="i-gts-paint-brush"
            :to="{ name: 'home', hash: '#layanan-jual' }"
          >
            <span>Produk</span>
          </app-dropdown-item>
          <app-dropdown-item
            :to="{ name: 'home', hash: '#layanan-jasa' }"
            icon="i-gts-home-hammer"
          >
            <span>Jasa</span>
          </app-dropdown-item>
          <app-dropdown-item
            icon="i-gts-scaffolding"
            :to="{ name: 'home', hash: '#layanan-sewa' }"
          >
            <span>Sewa</span>
          </app-dropdown-item>
        </template>
      </app-dropdown>
      <!-- <app-button
        class="menu-bar-item"
        :to="{ name: 'transaction list' }"
      >
        <app-icon
          class="icon-active"
          icon="i-gts-receipt"
        />
        <app-icon
          class="icon-inactive"
          icon="i-gts-receipt-gray"
        />
        <div>Transaksi</div>
      </app-button> -->
      <app-button
        class="menu-bar-item"
        :href="helpCenterUrl"
      >
        <app-icon icon="i-gts-user-headset-gray" />
        <div>Help Center</div>
      </app-button>
      <the-navbar-user-dropdown
        v-if="page.isAuthenticated"
        status="the-menu-bar"
      />
      <app-button
        v-else
        class="menu-bar-item"
        :to="{ name: 'login' }"
      >
        <app-icon icon="i-gts-login-gray" />
        <div>Masuk</div>
      </app-button>
    </div>
  </div>
</template>

<style lang="postcss">
  .menu-bar {
    @apply rounded-t-5 border border-b-0 border-primary bg-white;
    @apply shadow-figma;
    @apply flex items-center justify-around;
  }
  .menu-bar-item {
    @apply w-72px h-64px flex flex-col items-center text-lg justify-center text-center;

    div {
      @apply text-2xs block mt-2  text-gray-700 tracking-wider;
    }

    .icon-active {
      display: none;
    }
    &.router-link-active {
      .icon-active {
        display: initial;
      }
      .icon-inactive {
        display: none;
      }
    }
  }
</style>
