<script setup lang="ts">
  import { useAppModal } from '../modal'

  const { modal, open } = useAppModal()
  defineExpose({ open })
</script>

<template>
  <app-modal
    ref="modal"
    title="Download Aplikasi MASA"
  >
    <app-modal-description class="pb-2">
      Akses lebih cepat dan mudah ke semua
      <br />
      fitur dengan aplikasi resmi kami.
    </app-modal-description>
    <div class="flex flex items-center gap-4">
      <a
        href="https://apps.apple.com/id/app/masa-building-solution/id6636482849?itscg=30200&itsct=apps_box_badge&mttnsubad=6636482849"
        class="inline-block"
      >
        <!-- src="https://toolbox.marketingtools.apple.com/api/v2/badges/download-on-the-app-store/black/id-id?releaseDate=1731369600" -->
        <img
          src="/images/app-stores/download-at-app-store-indonesian.svg"
          alt="Download di App Store"
          class="aspect-3/1 h-48px object-contain align-middle"
        />
      </a>

      <a
        href="https://play.google.com/store/apps/details?id=id.solusibangunan.app&hl=id"
        target="_blank"
      >
        <img
          src="/images/app-stores/get-it-on-google-play-badge-web-color-indonesian.svg"
          alt="Dapatkan di Google Play"
          class="h-48px object-contain align-middle"
        />
      </a>
    </div>
  </app-modal>
</template>
